.footer-copyright {
  font-family: "DMSans-Regular";
  font-style: normal;
  font-weight: 400;
  background: #141517;
  padding: 25px 0;
  line-height: 1;
  font-size: 14px;
  line-height: 150%;
  /* color: #866565;; */
  color: #ffffff;

}

.footer-copyright a {
  font-family: "DM Sans";
  color: #1c71ff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .footer-copyright {
    border-radius: 50px;
  }
}