.web-menu {
  display: block;
  background: rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid #edeff3;
  backdrop-filter: blur(28px);
  height: 80px;

}

.app-bar {
  display: none;

}



header .logo {
  width: 100px;
  height: 50px;
}

.main-menu .nav-link {
  font-family: "DM Sans";
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  color: #5c6d8a;
  padding: 1rem 1rem;
  margin: 0rem 1.5rem;
}

.dropdown-menu {
  width: 14rem;
}

.menu {
  display: none;
  color: #ffffff;
  /* width: 200pc;
  height: 2rem; */
}

.app-bar .nav-hide {
  display: none;
}

@media only screen and (max-width: 600px) {
  .web-menu {
    display: none !important;
  }

  .app-bar {
    background: linear-gradient(180deg, #F0F5FF 19.32%, #FFFFFF 113.65%);
    border-bottom: 2px solid #D9D9D9;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding:0rem 1rem; */
  }
  .app-bar a {
    color: #000000;
    padding: 14px 16px;
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-family: "DMSans-Regular";
  }
  .app-bar .icon {
    width: 35px;
    height: 35px;
    margin: 1rem 1rem;
    object-fit: contain;
  }

  .app-bar .close-icon {
    display: flex;
    justify-content: end;
   
    ;

  }

  .mobile-menu {
    position: absolute;
    top: 0;
  }

  .app-bar .nav-show {
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding: 0;
    text-decoration: none;
    overflow-x: hidden;
    transition: 0.5s;

  }



  .menu {
    display: block;
  }



}