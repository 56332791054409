:root {
  --blackColor: #000000;
  --white: #ffffff;
  --blue:#543ee8;
  --grey:#949392;
  --contentColor:#5F2DED;
  --light-grey:#f0f0f5
}

@font-face {
  font-family: "DM Sans";
  src: url(../fonts/DM_Sans/DMSans_18pt-Bold.ttf);

}

@font-face {
  font-family: "DMSans-Regular";
  src: url(../fonts//DM_Sans//DMSans_24pt-Regular.ttf);

}

@font-face {
  font-family: "Manrope";
  src: url(../fonts/Manrope/Manrope-Bold.ttf);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.jk-btn{
  padding: 11px 18px 11px 22px ;
  min-width: 140px;
  max-width: 300px;
  height: 50px;
  font-size: 14px ;
  cursor: pointer;
  text-decoration: none;
  border-radius: 34px ;
  font-family: "DMSans-Regular";
  border:none
}
.btn {
  padding: 11px 18px 11px 22px !important;
  min-width: 140px;
  max-width: 300px;
  height: 50px;
  font-size: 14px !important;
  cursor: pointer;
  text-decoration: none;
  border-radius: 34px !important;
  font-family: "DMSans-Regular";
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.custom-btn {
  color: #ffffff !important;
  border-color: 1px solid #1c71ff;
  background: #1c71ff !important;
}

.secondary-btn {
  color: #1c71ff !important;
  border-color: 1px solid #ffffff;
  background: #ffffff !important;
}

.outlined-btn {
  background: transparent !important;
  border: 1px solid #1c71ff !important;
  color: #1c71ff !important;
}
.jsk-btn-lg{
  font-size: 18px;
    padding: 10px 20px;
}
.block-section {
  padding-bottom: 70px;
  padding-top: 70px;
}

.head-text {
  font-size: 40px;
  font-weight: bold;
  font-family: "Manrope";
  color: #001840;
  line-height: 54px;
}

.paragraph-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1b407e;
}

.styled-hr {
  background-color: #1c71ff;
  border: none;
  border-radius: 100px;
  height: 0.6rem !important;
  width: 8rem;
  opacity: 1;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #001840;
}

.grey-bg {
  background: #f3f6f8;
}


@media only screen and (max-width: 600px) {


  .head-text {
    font-size: 25px;

  }

  .block-section {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .section-title h2 {
    font-size: 25px;
  }

  .no-image img {
    width: 350px;
  }
  .jk-btn{
    height:40px;
    min-width: 120px;
    font-size: 12px;
  }
}